import { Crisp } from "crisp-sdk-web";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { clientAuth } from "../firebase/clientFirebase";
import {
  GoogleAuthProvider,
  OAuthProvider,
  signInWithPopup,
  User,
} from "@firebase/auth";
import { signOut } from "firebase/auth";
import { UserData } from "../types/userData";
import * as Sentry from "@sentry/nextjs";

export function useAuth() {
  const [authModalShowed, setAuthModalShowed] = useState(false);
  const [authPhoneModalShowed, setAuthPhoneModalShowed] = useState(false);

  const [callback, setCallback] = useState<(() => Promise<void>) | null>(null);

  const [user, setUser] = useState<User | null>(null);
  const [userData, setUserData] = useState<UserData | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const unsubscribe = clientAuth.onAuthStateChanged(function handleAuth(
      user
    ) {
      if (user) {
        setUser(user);
        setLoading(false);

        // crisp
        if (user.displayName) Crisp.user.setNickname(user.displayName);

        Crisp.session.setData({
          uid: user.uid,
        });
        if (user.email) Crisp.user.setEmail(user.email);
        if (user.photoURL) Crisp.user.setAvatar(user.photoURL);

        // sentry
        Sentry.setUser({
          uid: user.uid,
          providerData: user.providerData,
          email: user.email ?? "",
        });
      } else {
        // frontend
        setUser(null);
        setLoading(false);
        setUserData(null);
      }
    });

    return unsubscribe;
  }, []);

  const showAuthModal = (callback: (() => Promise<void>) | null = null) => {
    setAuthModalShowed(true);
    setCallback(() => callback);
  };

  const closeAuthModal = () => {
    setAuthModalShowed(false);
  };

  const showAuthPhoneModalShowed = () => {
    console.log("showAuthPhoneModalShowed");
    setAuthPhoneModalShowed(true);
  };

  const closeAuthPhoneModalShowed = () => {
    setAuthPhoneModalShowed(false);
  };

  const signOutHandler = async () => {
    setUser(null);
    setUserData(null);

    // crisp clearing session
    Crisp.session.reset();
    // sentry clearing session
    Sentry.setUser(null);

    toast.success("Вы успешно вышли");
    await signOut(clientAuth);
  };

  const signInWithGoogle = () => {
    const provider = new GoogleAuthProvider();

    signInWithPopup(clientAuth, provider)
      .then(async (result) => {
        toast.success("Вы успешно вошли");
        if (callback !== null) {
          await callback();
        }
        setAuthModalShowed(false);
      })
      .catch((error) => {
        console.log(error);
        toast.error("Что-то пошло не так");
      });
  };

  const signInWithApple = () => {
    const provider = new OAuthProvider("apple.com");

    signInWithPopup(clientAuth, provider)
      .then(async (result) => {
        toast.success("Вы успешно вошли");
        if (callback !== null) {
          await callback();
        }
        setAuthModalShowed(false);
      })
      .catch((error) => {
        console.log(error);
        toast.error("Что-то пошло не так");
      });
  };

  return {
    user,
    userData,
    loading,
    signInWithGoogle,
    signInWithApple,
    // signInWithPhone,
    // confirmCode,
    signOutHandler,
    authModalShowed,
    showAuthModal,
    closeAuthModal,
    authPhoneModalShowed,
    showAuthPhoneModalShowed,
    closeAuthPhoneModalShowed,
    callback,
  };
}
