import { getAnalytics } from "@firebase/analytics";
import { FirebaseOptions, getApp, initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import {
  collection,
  CollectionReference,
  getFirestore,
} from "@firebase/firestore";
import firebase from "firebase/compat";
import DocumentData = firebase.firestore.DocumentData;
import { Payment } from "../types/payment";
import { Game } from "../types/game";

const config: FirebaseOptions = {
  appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_PUBLIC_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
  messagingSenderId: process.env.NEXT_PUBLIC_MESSAGING_SENDER_ID,
};

function createFirebaseApp(config: FirebaseOptions) {
  try {
    return getApp();
  } catch {
    return initializeApp(config);
  }
}

const clientFirebaseApp = createFirebaseApp(config);

export const clientAuth = getAuth(clientFirebaseApp);
export const clientFirestore = getFirestore(clientFirebaseApp);

const createCollection = <T = DocumentData>(collectionName: string) => {
  return collection(clientFirestore, collectionName) as CollectionReference<T>;
};

export const paymentsCol = createCollection<Payment>("payments");
export const gamesCol = createCollection<Game>("games");
