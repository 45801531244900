import Link from "next/link";
import { useRouter } from "next/router";
import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { docTermsKc } from "../../src/constants";
import { useAuthContext } from "../../src/contexts/authContext";

export default function ShowLoginModal() {
  const {
    authModalShowed,
    closeAuthModal,
    signInWithGoogle,
    signInWithApple,
    showAuthPhoneModalShowed,
  } = useAuthContext();

  return (
    <Transition.Root show={authModalShowed} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => closeAuthModal()}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-900 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full justify-center p-4 text-center items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-gray-200 px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div className="max-w-md w-full space-y-8">
                  <div>
                    <h2 className="mt-3 text-center text-xl font-medium">
                      Войти в аккаунт
                    </h2>
                  </div>
                  <input type="hidden" name="remember" defaultValue="true" />
                  <div>
                    <div className="mt-1 grid gap-3">
                      <div>
                        <a
                          onClick={signInWithGoogle}
                          className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-900 hover:bg-gray-50"
                        >
                          <svg
                            className="mr-2 mt-1 -ml-1 w-4 h-4"
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fab"
                            data-icon="google"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 488 512"
                          >
                            <path
                              fill="currentColor"
                              d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z"
                            ></path>
                          </svg>

                          <span>Войти через Google</span>
                        </a>
                      </div>
                      <div>
                        <a
                          onClick={signInWithApple}
                          className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-900 hover:bg-gray-50"
                        >
                          <svg
                            className="mr-2 -ml-1 w-5 h-5"
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fab"
                            data-icon="apple"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                          >
                            <path
                              fill="currentColor"
                              d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z"
                            ></path>
                          </svg>
                          Войти через Apple
                        </a>
                      </div>
                      <div>
                        <a
                          onClick={() => {
                            showAuthPhoneModalShowed();
                          }}
                          className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-900 hover:bg-gray-50"
                        >
                          Войти через СМС
                        </a>
                      </div>
                      <div className="text-[0.9rem] leading-[0.9rem] mt-3">
                        Продолжая дальше, Вы принимаете условия{" "}
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={docTermsKc}
                          className="inline-flex py-1 rounded-md text-[0.9rem] underline underline-offset-4"
                        >
                          пользовательского соглашения
                        </a>{" "}
                        <br />и{" "}
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href="/privacy"
                          className="inline-flex py-1 rounded-md text-[0.9rem] underline underline-offset-4"
                        >
                          политики конфиденциальности
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
