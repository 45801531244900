import { createContext, ReactNode, useContext } from "react";
import { useAuth } from "../hooks/useAuth";
import { User } from "@firebase/auth";
import { UserData } from "../types/userData";

type AuthContextInterface = {
  readonly authModalShowed: boolean;
  readonly showAuthModal: (callback: (() => Promise<void>) | null) => void;
  readonly closeAuthModal: () => void;
  readonly loading: boolean;
  readonly authPhoneModalShowed: boolean;
  readonly showAuthPhoneModalShowed: () => void;
  readonly closeAuthPhoneModalShowed: () => void;
  readonly signInWithGoogle: () => void;
  readonly signInWithApple: () => void;
  readonly signOutHandler: () => Promise<void>;
  readonly user: User | null;
  readonly userData: UserData | null;
  readonly callback: (() => Promise<void>) | null;
};

const initialValue = {} as AuthContextInterface;

export const AuthContext = createContext(initialValue);

export function AuthProvider({ children }: { children: ReactNode }) {
  const {
    authModalShowed,
    showAuthModal,
    closeAuthModal,
    loading,
    signInWithGoogle,
    signInWithApple,
    signOutHandler,
    authPhoneModalShowed,
    showAuthPhoneModalShowed,
    closeAuthPhoneModalShowed,
    user,
    userData,
    callback,
  } = useAuth();

  return (
    <AuthContext.Provider
      value={{
        authModalShowed,
        closeAuthModal,
        loading,
        showAuthModal,
        signInWithGoogle,
        signInWithApple,
        signOutHandler,
        authPhoneModalShowed,
        showAuthPhoneModalShowed,
        closeAuthPhoneModalShowed,
        user,
        userData,
        callback,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuthContext() {
  return useContext(AuthContext);
}
