import "../styles/globals.css";
import type { AppProps } from "next/app";
import dynamic from "next/dynamic";
import Script from "next/script";
import React, { ReactElement, ReactNode, useEffect } from "react";
import { NextComponentType, NextPageContext } from "next/dist/shared/lib/utils";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ShowLoginModal from "../components/modals/ShowLoginModal";
import { AuthProvider } from "../src/contexts/authContext";
import { Crisp } from "crisp-sdk-web";

export type NextPageWithLayout<P = {}, IP = P> = NextComponentType<
  NextPageContext,
  IP,
  P
> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

const ShowLoginWithPhoneModal = dynamic(
  () => import("../components/modals/ShowLoginWithPhoneModal"),
  {
    ssr: false,
  }
);

export default function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  // gets screen size - to fix mobile viewport height problem
  useEffect(() => {
    // only execute all the code below in client side
    if (typeof window !== "undefined") {
      // Handler to call on window resize
      const handleResize = () => {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty("--vh", `${vh}px`);
      };

      // Add event listener
      window.addEventListener("resize", handleResize);

      // Call handler right away so state gets updated with initial window size
      handleResize();

      // Remove event listener on cleanup
      return () => window.removeEventListener("resize", handleResize);
    }
  }, []);

  useEffect(() => {
    Crisp.configure(process.env.NEXT_PUBLIC_CRISP_ID ?? "");
  }, []);

  const getLayout = Component.getLayout ?? ((page) => page);

  return (
    <AuthProvider>
      <ToastContainer />

      <ShowLoginModal />

      <ShowLoginWithPhoneModal />

      {getLayout(<Component {...pageProps} />)}
    </AuthProvider>
  );
}
