export const yookassaShopId = process.env.YOOKASSA_SHOP_ID ?? "";
export const yookassaSecretKey = process.env.YOOKASSA_SECRET_KEY ?? "";

export const countOfFreeQuestions = 11;
export const showPayWallCounter = 10;
export const defaultColor = "#B0B0B0";

export const legalDocUrl =
  "https://docs.google.com/document/d/e/2PACX-1vRD20GfT1997l3GAupamDR_D6mQB8HYLxZII2mQHY1MW3QfYn1F5paEcgYGaY0V65oJ-CQCnnbozDEv/pub";

export const telegramBotName = process.env.NEXT_PUBLIC_TELEGRAM_BOT_NAME ?? "";

export const telegramBotSupport = `https://t.me/${telegramBotName}`;

export const instagramUrl = "https://instagram.com/eto.znak.mag";
export const telegramUrl = "https://t.me/etoznakmag";
export const etoZnakStoreUrl = "https://etoznakstore.ru";

export const docTerms = "/docs/terms.pdf";
export const docTermsKc = "/docs/terms-kc.pdf";

export enum GenderEnum {
  male = "male",
  female = "female",
}

export enum GameEnum {
  forOne = "for-one",
  forTwo = "for-two",
  forTeam = "for-team",
  newYear = "new-year",
}

export const gamesWithGender = [GameEnum.forOne, GameEnum.newYear];

export const metaTitle = "eto znak game";
export const metaDescription =
  "Уникальная игра, которая выведет твои отношения с внешним и внутренним миром на новый уровень";
export const metaOgImage = `${process.env.BASE_DOMAIN ?? ""}/preview.png`;
export const metaOgImageWidth = "1280";
export const metaOgImageHeight = "628";
export const metaOgTitle = "eto znak game";
export const metaOgDescription =
  "Уникальная игра, которая выведет твои отношения с внешним и внутренним миром на новый уровень";
